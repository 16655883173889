import $ from 'jquery'
import M from 'materialize-css'

const MainMenu = () => {
  return (
    <ul className="MainMenu">
      <li><a href="#Home">Home</a></li>
      <li><a href="#Counseling-Sessions">Counseling Sessions</a></li>
      <li><a href="#Our-Work">Our Work</a></li>
      <li><a href="#About">About</a></li>
      <li><a href="#Contact">Contact</a></li>
    </ul>
  );
};


export default MainMenu;
