const About = () => {
  return (
    <div className="about panel grey darken-2">
        
      <a className="anchor" name="About" />

      <div className="container white">
        <div className="row">
          <div className="col s1">&nbsp;</div>
          <div className="col s10">
            <h5>Why Horse Therapy?</h5>
            <div className="hr"></div>
            
            <p>&nbsp;</p>
            
            <p><span>Our similarities with horses create an avenue for us to connect with them on a deep, emotional level, allowing us to grow and heal in ways that are otherwise difficult.</span></p>

            <p>&nbsp;</p>

            <p>Equine therapy is a way to utilize the connection between people and horses to promote mental, emotional, and physical healing.&nbsp;</p>

            <p>&nbsp;</p>

            <p>There are three different types of equine therapy: psychotherapy, hippotherapy, and therapeutic riding. Here at Galloping Greatly, we focus on psychotherapy.</p>

            <p>&nbsp;</p>

            <p>Equine-assisted psychotherapy focuses on mental aspects, including mental disorders, cognitive issues and traumatic experiences. The presence of the horse helps the patient better identify and process their feelings and bring healing to their emotional issues.</p>

            <p>&nbsp;</p>

            <p>The use of horses is purposeful and strategic. At its root, the purpose behind using horses for therapeutic reasons is that horses are similar to people. Horses have unique personalities, moods, and attitudes that patients can connect with and relate to.&nbsp;</p>

            <p>&nbsp;</p>

            <p>Horses have to be very sensitive to their environment in order to stay safe from predators, meaning they are very aware of their surroundings and able to adapt. They will instinctively analyze and react to our mood, body language and other nonverbal cues that can provide insight and feedback to our lives.&nbsp;</p>

            <p>&nbsp;</p>

            <p>Here at Galloping Greatly, we value our patients' growth in any area they need and have the resources necessary for complete healing.&nbsp;</p>
          </div>
          <div className="col s1">&nbsp;</div>
        </div>
        

      </div>
    </div>
  );
};

export default About;
