const OurWork = () => {
  return (
    <div className="ourwork panel">
      <div className="container">

        <a className="anchor" name="Our-Work" />

        <div className="row">
          <h5>OUR WORK</h5>
          <div className="hr"></div>
                      
          <p className="center">
          We serve Grant County and surrounding counties by providing individual, family, and group counseling using Equine Assisted Therapy, alongside of other treatment modalities, to empower change in a positive, holistic way. We provide services for anxiety, depression, trauma, and grief, as well as behavioral issues and conflict resolution skills.
          </p>
        </div>
        

      </div>
    </div>
  );
};

export default OurWork;
